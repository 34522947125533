<template>
  <div class="cadastro-usuario">
    <v-container fluid>
      <h1 class="titulo">Lançamento Financeiro</h1>
      <div class="box-table">
        <v-skeleton-loader
            :loading="loadingTable"
            :transition="'fade-transition'"
            height="500"
            type="table">
          <v-data-table
            :headers="headers"
            :items="listaItens"
            sort-by="nome"
            :search="search"
            width="300px"
            flat
            hide-default-footer
            :loading="loadingPag"
            :items-per-page.sync="itemsPerPage"
          > 

            <template v-slot:top>
              <!-- <BrToolBar @search="buscar" :configFilter="{ 
                  listaSelect: selectKeys, 
                  getItens: getItens,
                  jsonData: false }"
                  :addlFilter="true"
                  :labelTextField="'Busca por nome da entidade'" /> -->
                  
              <FiltroBase v-model="filtros" :loading="loadingPag" />
              
            </template>

            <template v-slot:item.value="{ item }">
              {{ item.value | currency }}
            </template>
            <template v-slot:item.status="{ item }">
                <tooltipLancamento  :item="item" :key="item.id" :id="item.id" />
            </template>

            <template v-slot:item.name="{ item }">
              <span @click.prevent="openModalDadosEntidades(item)" class="opem-modal-info">
                {{ item.name }}
              </span>
            </template>

            <template v-slot:item.action="{ item }">
              <v-btn
                class="mr-2"
                tile
                small
                outlined 
                :color="variables.colorPrimary"
                @click.stop="editar(item)"
                v-if="permiteAcao($route, 'edit') && item.isManual && item.status !== 'B' && item.pendingOperationTypeId !== 'PC'">
                <!-- Lançamento -->
                Editar
              </v-btn>

               <v-btn
                  class="mr-2"
                  tile
                  small
                  outlined 
                  :color="variables.colorPrimary"
                  @click.stop="baixar(item)"
                  v-if="permiteAcao($route, 'edit') && item.isManual && item.status !== 'B' && item.pendingOperationTypeId !== 'PC'">
                  <!-- Lançamento -->
                  {{ item.status === 'B' ? 'Cancelar' : 'Baixar'}}
                </v-btn>

                <v-btn
                  small
                  @click.stop="deleteItem(item)"
                  tile
                  outlined 
                  :color="variables.colorError"
                  v-if="permiteAcao($route, 'delete') && item.pendingOperationTypeId === 'LCX' &&  item.status !== 'B'">
                  Excluir
                </v-btn>
            </template>

            <template v-slot:item.params="{ item }">
               <img class="icon-boleto" src="@/assets/img/fatura.svg" width="20" v-if="hasBillet(item.params)" @click="redirectBillet(item.params)"/>
            </template>

            <template v-slot:footer>
              <div class="wrapper-footer">
                <div class="qtd-paginacao">
                  {{ pageStart }}
                  -
                  {{ pageStop }}
                  de
                  {{ totalItens }}
                </div>
                <v-pagination
                  v-model="page"
                  :length="numberOfPages"
                  circle
                  :disabled="disabled"
                  :total-visible="7"
                  class="box-table-paginacao"
                  v-if="numberOfPages > 1"></v-pagination>
              </div>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </v-container>

    <InfoModal />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { errorSnackbar, successSnackbar, currency } from '@/core/service/utils'
import Events from '@/core/service/events'
import moment from 'moment'
// MIXINS
import listagemTables from '@/views/mixins/listagemTables'

export default {
  name: 'Cadastros',
  mixins: [listagemTables],
  components: {
    // BrToolBar: () => import('./components/toolbar'),
    InfoModal: () => import('@/views/components/modalDadosEntidades'),
    tooltipLancamento: () => import('./components/tooltipLancamentos'),
    FiltroBase: () => import('./components/filtroBase')
    // ModalLancamentoFinanceiro
  },
  data: () => ({
    lastFIltro: {},
    filtros: {},
    headers: [
      // { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Código Entidade', value: 'entity.cod' },
      { align: 'start', class: 'table-header', text: 'Tipo operação', value: 'pendingOperationTypeName' },
      { align: 'start', class: 'table-header', text: 'Valor', value: 'value' },
      { align: 'start', class: 'table-header', text: 'Data', value: 'date' },
      { align: 'start', class: 'table-header', text: 'Status', value: 'status' },
      // { align: 'center', class: 'table-header', text: 'Boleto', value: 'params' },
      { align: 'center', class: 'table-header', text: 'Criado', value: 'user.name' },
      { align: 'center', class: 'table-header', text: 'Baixado', value: 'updateUser.name' },
      { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ],
    idLancamentoFinanceiro: null,
  }),
  mounted () {
    const vm = this

    let path = ['A.R.C.CC', 'A.R.C.CD', 'A.R.PGTR']
    vm.listaTypes({ path })
    this.buscarDatas()
    Events.$on('table::loadPage', e => {
      vm.loadingPag = e
    })
  },
  computed: {
    ...mapGetters('lancamentoFinanceiro', ['listaItens', 'totalItens', 'lancamentoFinanceiro']),
    selectKeys () {
      return {
        'pendingOperation.created': '',
        value: '',
        status: '',
        pendingOperationTypeId: '',
        pendingOperationType: {
          name: ''
        },
        Entities: {
          name: '',
          cod: ''
        }
      }
    }
  },

  methods: {
    ...mapActions('lancamentoFinanceiro', ['getItens','clearItens', 'setItem', 'baixarLancamentoFinanceiro', 'deletarItem']),
    ...mapActions('entryTypes', {'listaTypes': 'getItens'}),
    init() {},
    paginacao (pag) {      
      this.loadingPag = true
      this.getItens({...this.filtros, page: pag || 1, pageSize: this.$store.getters.ItensPaginacao }).then(result => {
        if (result.data.totalItens < 1) errorSnackbar('Não há dados disponíveis')          
      })
      .catch(err => {
        errorSnackbar(err.error)
      })
      .finally(() => {
        this.loadingPag = false        
        this.loadingTable = false
      })
    },
    buscar () {
      this.loadingPag = true

      this.getItens(this.filtro).then(result => {
          if (result.data.totalItens < 1) errorSnackbar('Não há dados disponíveis')          
        })
        .catch(err => {
          errorSnackbar(err.error)
        })
        .finally(() => {
          this.loadingPag = false
          this.loadingTable = false
         })
    },
    
    buscarDatas (page) {
      this.lastFIltro = {
        '_addlFilter': {
          'pendingOperation:created_>=': moment().format('YYYY-MM-DD [00:00:00]'),
          'pendingOperation:created_<=': moment().format('YYYY-MM-DD [23:59:59]')
        }
      }

      let filtro = {'_addlFilter': Object.assign(this.lastFIltro['_addlFilter'] , this.objFilter['_addlFilter'])}
      this.getItens({...filtro, page: page || 1, pageSize: this.$store.getters.ItensPaginacao }).then(result => {
        if (result.data.totalItens < 1) errorSnackbar('Não há dados disponíveis')          
      })
      .catch(err => {
        errorSnackbar(err.error)
      })
      .finally(() => {
        this.loadingPag = false
        this.loadingTable = false
      })
    },
  
    baixar (item) {
      if (item.status === 'B') {
        this.$swal({
          icon: 'error',
          text: `Prêmio ja baixado`,
        })

        return false
      }


      this.$swal({
        icon: 'warning',
        text: `Confirma lançamento N° ${item.id} no valor de ${currency(item.value)} nada data de ${item.date}`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.value) {
          this.loadingPag = true
          this.baixarLancamentoFinanceiro({ id: item.id }).then(() => {
            this.getItens()
              .finally(() => this.loadingPag = false)
            successSnackbar('Lançamento confirmado com sucesso!')
          }).catch(err => {
            this.loadingPag = false
            errorSnackbar(err)
          })
        }
      })
    },

    editar (item) {
      Events.$emit('cadastro::openModalCadastro', item)
    },

    // lancamentoFinanceiro (item) {
    //   this.getItem(item)
    // }

    getColor (status) {
      return status === 'B' ? this.variables.colorSecondary : this.variables.colorPrimary
    },

    hasBillet (params) {
      if (params && params.paymentData && params.paymentData.type === 'boleto') return true

      return false
    },

    redirectBillet (params) {
      if (params && params.paymentData && params.paymentData.type === 'boleto') window.open(params.paymentData.data.url_acesso, '_blank')
    },

    openModalDadosEntidades (item) {
      Events.$emit('entidades::modalDados', {item: item.entity, type: 'lancamento-financeiro'})
    }
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/view/usuarios-web';
  .opem-modal-info {
    cursor: pointer;
  }
.filtro-range button {
  min-height: 40px !important;
}
</style>

