var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cadastro-usuario"},[_c('v-container',{attrs:{"fluid":""}},[_c('h1',{staticClass:"titulo"},[_vm._v("Lançamento Financeiro")]),_c('div',{staticClass:"box-table"},[_c('v-skeleton-loader',{attrs:{"loading":_vm.loadingTable,"transition":'fade-transition',"height":"500","type":"table"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.listaItens,"sort-by":"nome","search":_vm.search,"width":"300px","flat":"","hide-default-footer":"","loading":_vm.loadingPag,"items-per-page":_vm.itemsPerPage},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('FiltroBase',{attrs:{"loading":_vm.loadingPag},model:{value:(_vm.filtros),callback:function ($$v) {_vm.filtros=$$v},expression:"filtros"}})]},proxy:true},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.value))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('tooltipLancamento',{key:item.id,attrs:{"item":item,"id":item.id}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"opem-modal-info",on:{"click":function($event){$event.preventDefault();return _vm.openModalDadosEntidades(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.permiteAcao(_vm.$route, 'edit') && item.isManual && item.status !== 'B' && item.pendingOperationTypeId !== 'PC')?_c('v-btn',{staticClass:"mr-2",attrs:{"tile":"","small":"","outlined":"","color":_vm.variables.colorPrimary},on:{"click":function($event){$event.stopPropagation();return _vm.editar(item)}}},[_vm._v(" Editar ")]):_vm._e(),(_vm.permiteAcao(_vm.$route, 'edit') && item.isManual && item.status !== 'B' && item.pendingOperationTypeId !== 'PC')?_c('v-btn',{staticClass:"mr-2",attrs:{"tile":"","small":"","outlined":"","color":_vm.variables.colorPrimary},on:{"click":function($event){$event.stopPropagation();return _vm.baixar(item)}}},[_vm._v(" "+_vm._s(item.status === 'B' ? 'Cancelar' : 'Baixar')+" ")]):_vm._e(),(_vm.permiteAcao(_vm.$route, 'delete') && item.pendingOperationTypeId === 'LCX' &&  item.status !== 'B')?_c('v-btn',{attrs:{"small":"","tile":"","outlined":"","color":_vm.variables.colorError},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_vm._v(" Excluir ")]):_vm._e()]}},{key:"item.params",fn:function(ref){
var item = ref.item;
return [(_vm.hasBillet(item.params))?_c('img',{staticClass:"icon-boleto",attrs:{"src":require("@/assets/img/fatura.svg"),"width":"20"},on:{"click":function($event){return _vm.redirectBillet(item.params)}}}):_vm._e()]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"wrapper-footer"},[_c('div',{staticClass:"qtd-paginacao"},[_vm._v(" "+_vm._s(_vm.pageStart)+" - "+_vm._s(_vm.pageStop)+" de "+_vm._s(_vm.totalItens)+" ")]),(_vm.numberOfPages > 1)?_c('v-pagination',{staticClass:"box-table-paginacao",attrs:{"length":_vm.numberOfPages,"circle":"","disabled":_vm.disabled,"total-visible":7},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)]},proxy:true}])})],1)],1)]),_c('InfoModal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }